import React, { useEffect, useState } from 'react'
import { FaBirthdayCake } from "react-icons/fa";
import { fetchAllDataAPI } from '../utils/api';
import LoadingSpinners from './LoadingSpinners';
import moment from 'moment';

const Anniversaries = () => {
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";
    const [anniversary, setAnniversary] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllAnniversaryThisMonth();
    }, []);

    const getAllAnniversaryThisMonth = () => {
        setIsLoading(true);
        fetchAllDataAPI('employee-get-all-anniversaries').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setAnniversary(...[res.data.anniversaries])
            }
            else {
                setIsLoading(false)
            }
        })
    }

    return (
        <div className="card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center">
                    <div>
                        <h6 className="mb-0">Anniversaries</h6>
                    </div>
                </div>
            </div>
            <div className="anniversariesection">
                {isLoading ?
                    <LoadingSpinners />
                    :
                    anniversary?.map((anniversary, index) => (
                        <div className="anniversariebody" key={index}>
                            <div className="img">
                                <img className="rounded-circle" src={BASE_URL + anniversary?.emp_foto} alt={anniversary?.emp_foto} />
                            </div>
                            <div className="nametitle">
                                <h6>{anniversary?.emp_name}</h6>
                                <p>{moment(anniversary?.emp_aniversary_date).format('ddd, Do MMM')} {moment().format('YYYY')}</p>
                            </div>
                            <div className="icons">
                                <FaBirthdayCake />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Anniversaries