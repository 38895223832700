import React, { useEffect, useState } from 'react'
import { getSingleDataFromDatabase } from '../utils/api';
import LoadingSpinners from './LoadingSpinners';
import moment from 'moment';

const LeaveApproved = () => {
    const [approvel, setApprovel] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const empid = localStorage.getItem('empdata');

    useEffect(() => {
        getLeaveRequestApprovel(empid)
    }, [])
    const getLeaveRequestApprovel = (empid) => {
        setIsLoading(true)
        getSingleDataFromDatabase(`employee-get-all-leave-request-approvel/${empid}`).then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setApprovel(...[res.data.approvel])
            }
            else {
                setIsLoading(false)
            }
        })
    }
    return (
        <div className="card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center">
                    <div>
                        <h6 className="mb-0">Leave Approvals Status</h6>
                    </div>
                </div>
            </div>
            <div className="approvalsection">
                {isLoading ?
                    <LoadingSpinners />
                    :
                    approvel?.map((approvel, index) => (
                        <>
                            {(() => {
                                if (approvel?.leave_status === "Approved") {
                                    return (
                                        <div className="approvalbody" style={{ borderLeft: "5px solid green" }}>
                                            <div className="requestdate">
                                                <h6>Leave Request</h6>
                                                <p>{moment(approvel?.request_date).format('ddd, Do MMM YYYY')}</p>
                                            </div>
                                            <div className="requesttype">
                                                <h6>{approvel?.leave_type}</h6>
                                                <p>{moment(approvel?.leave_start_date).format('ddd, Do MMM YYYY')}</p>
                                            </div>
                                            <div className="leavestatus">
                                                <h6>Status:</h6>
                                                <p><span className="badge bg-success">{approvel?.leave_status}</span></p>
                                            </div>
                                        </div>
                                    )
                                }
                                else if (approvel?.leave_status === "Reject") {
                                    return (
                                        <div className="approvalbody" style={{ borderLeft: "5px solid red" }}>
                                            <div className="requestdate">
                                                <h6>Leave Request</h6>
                                                <p>{moment(approvel?.request_date).format('ddd, Do MMM YYYY')}</p>
                                            </div>
                                            <div className="requesttype">
                                                <h6>{approvel?.leave_type}</h6>
                                                <p>{moment(approvel?.leave_start_date).format('ddd, Do MMM YYYY')}</p>
                                            </div>
                                            <div className="leavestatus">
                                                <h6>Status:</h6>
                                                <p><span className="badge bg-danger">{approvel?.leave_status}</span></p>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className="approvalbody" style={{ borderLeft: "5px solid yellow" }}>
                                            <div className="requestdate">
                                                <h6>Leave Request</h6>
                                                <p>{moment(approvel?.request_date).format('ddd, Do MMM YYYY')}</p>
                                            </div>
                                            <div className="requesttype">
                                                <h6>{approvel?.leave_type}</h6>
                                                <p>{moment(approvel?.leave_start_date).format('ddd, Do MMM YYYY')}</p>
                                            </div>
                                            <div className="leavestatus">
                                                <h6>Status:</h6>
                                                <p><span className="badge bg-warning">{approvel?.leave_status}</span></p>
                                            </div>
                                        </div>
                                    )
                                }
                            })()}
                        </>
                    ))
                }

            </div>
        </div>
    )
}

export default LeaveApproved