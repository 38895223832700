import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import MobileOTP from "./pages/MobileOTP";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import LeaveReport from "./pages/LeaveReport";
import AddLeave from "./pages/AddLeave";
import MyProfile from "./pages/MyProfile";
import EditMyProfile from "./pages/EditMyProfile";
import TeamMember from "./pages/TeamMember";
import AddTeamMember from "./pages/AddTeamMember";


function App() {
  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mobile-otp" element={<MobileOTP />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/leave-report" element={<LeaveReport />} />
          <Route path="/add-leave" element={<AddLeave />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/edit-my-profile/:slug" element={<EditMyProfile />} />
          <Route path="/team-member" element={<TeamMember />} />
          <Route path="/add-team-member" element={<AddTeamMember />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
