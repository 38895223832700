import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { addDataAPI, fetchAllDataAPI } from '../utils/api'
import { toast } from 'react-toastify'
import { GoSingleSelect } from 'react-icons/go'

const AddTeamMember = () => {
    const navigate = useNavigate();
    const [values, setUserValue] = useState();
    const empid = localStorage.getItem('empdata');
    const [emp, setEmp] = useState([]);

    useEffect(() => {
        getAllActiveEmployee()
    }, []);

    const getAllActiveEmployee = () => {
        fetchAllDataAPI('employee-get-all-active-employee').then((res) => {
            if (res.data.status === 1) {
                setEmp(...[res.data.emp])
            }
        })
    }
    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddTeamMember = async (e) => {
        e.preventDefault();
        if (values) {
            try {
                const data = { member_leader_id: empid, member_emp_id: values.member_emp_id };
                addDataAPI('employee-add-tem-member', data).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Team member has been added successfully!");
                        navigate('/team-member');
                    }
                    else if(res.data.status === 2){
                        toast.warning("Team member already added!!");
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        else {
            toast.error("Server internal error!!");
        }
    }
    return (
        <div className="empDashboard">
            <Helmet>
                <title>SATKARTAR:EMPLOYEE::ADD TEAM MEMBER</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/team-member">Team Member</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Add Team Member</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-6">
                        <div className="generalForm">
                            <div className="card border-top border-0 border-4 border-danger">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-center">
                                        <div><i className="bx bxs-user me-1 font-22 text-danger"></i>
                                        </div>
                                        <h5 className="mb-0 text-danger">Add Team Member</h5>
                                    </div>
                                    <hr />
                                    <form className="row g-3" onSubmit={handleAddTeamMember}>
                                        <div className="col-md-12">
                                            <label htmlFor="member_emp_id" className="form-label">Select Member</label>
                                            <div className="input-group">
                                                <span className="input-group-text bg-transparent"><GoSingleSelect /></span>
                                                <select className="form-control border-start-0" id="member_emp_id" name="member_emp_id" placeholder="First Name" onChange={handleInputs} required={true}>
                                                    <option value="">Select Member</option>
                                                    {emp?.map((emp) => (
                                                        <option value={emp?.employee_id}>{emp?.emp_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-danger px-5">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddTeamMember