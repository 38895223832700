import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { GoSingleSelect } from "react-icons/go";
import { MdOutlineDateRange, MdOutlineSubject } from "react-icons/md";
import { addDataAPI } from '../utils/api';
import { toast } from 'react-toastify';

const AddLeave = () => {
    const navigate = useNavigate();
    const [values, setUserValue] = useState();
    const empid = localStorage.getItem('empdata');

    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddLeave = async (e) => {
        e.preventDefault();
        if (values) {
            try {
                const data = {emp_id:empid, leave_type:values.leave_type, leave_subject:values.leave_subject, leave_start_date:values.leave_start_date, leave_end_date:values.leave_end_date, leave_message:values.leave_message};
                addDataAPI('employee-add-leave', data).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Leave has been added successfully!");
                        navigate('/leave-report');
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        else {
            toast.error("Server internal error!!");
        }
    }

    return (
        <div className="empDashboard">
            <Helmet>
                <title>SATKARTAR:EMPLOYEE::ADD LEAVE</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/leave-report">Leave Report</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Add Leave</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-6">
                        <div className="generalForm">
                            <div className="card border-top border-0 border-4 border-danger">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-center">
                                        <div><i className="bx bxs-user me-1 font-22 text-danger"></i>
                                        </div>
                                        <h5 className="mb-0 text-danger">Add Leave</h5>
                                    </div>
                                    <hr />
                                    <form className="row g-3" onSubmit={handleAddLeave}>
                                        <div className="col-md-12">
                                            <label htmlFor="leave_type" className="form-label">Leave Type</label>
                                            <div className="input-group">
                                                <span className="input-group-text bg-transparent"><GoSingleSelect /></span>
                                                <select className="form-control border-start-0" id="leave_type" name="leave_type" placeholder="First Name" onChange={handleInputs} required={true}>
                                                    <option value="">Select Leave Type</option>
                                                    <option value="Short Leave">Short Leave</option>
                                                    <option value="Sick Leave">Sick Leave</option>
                                                    <option value="Medical Leave">Medical Leave</option>
                                                    <option value="Casual Leave">Casual Leave</option>
                                                    <option value="Marriage Leave">Marriage Leave</option>
                                                    <option value="Short Leave">Short Leave</option>
                                                    <option value="Full Day Leave">Full Day Leave</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="leave_start_date" className="form-label">Start Date</label>
                                            <div className="input-group">
                                                <span className="input-group-text bg-transparent"><MdOutlineDateRange /></span>
                                                <input type="date" name="leave_start_date" className="form-control border-start-0" id="leave_start_date" placeholder="Phone No" onChange={handleInputs} required={true} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="leave_end_date" className="form-label">End Date</label>
                                            <div className="input-group">
                                                <span className="input-group-text bg-transparent"><MdOutlineDateRange /></span>
                                                <input type="date" name="leave_end_date" className="form-control border-start-0" id="leave_end_date" placeholder="Phone No" onChange={handleInputs} required={true} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="leave_subject" className="form-label">Subject Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text bg-transparent"><MdOutlineSubject />
                                                </span>
                                                <input type="text" className="form-control border-start-0" id="leave_subject" name="leave_subject" placeholder="Subject Name" onChange={handleInputs} required={true} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="leave_message" className="form-label">Message</label>
                                            <textarea className="form-control" id="leave_message" placeholder="Enter Message" rows="3" name="leave_message" onChange={handleInputs} required={true}></textarea>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-danger px-5">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddLeave