import React from 'react'

const AttendanceTwo = () => {
    return (
        <div className="card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center">
                    <div>
                        <h6 className="mb-0">Punching In And Out</h6>
                    </div>
                </div>
            </div>
            <div className="attendancebody">
                <div className="attendancesection">
                    <div className="attendanceTwo">
                        <div className="today"><h6>Today</h6></div>
                        <div className="inout">
                            <span className="in">
                                <p className="ins">In</p>
                                <p className="times">10:00 AM</p>
                            </span>
                            <span className="out">
                                <p className="outs">Out</p>
                                <p className="times">6:00 PM</p>
                            </span>
                        </div>
                    </div>
                    <div className="attendanceTwo">
                        <div className="today"><h6>Yesterday</h6></div>
                        <div className="inout">
                            <span className="in">
                                <p className="ins">In</p>
                                <p className="times">10:00 AM</p>
                            </span>
                            <span className="out">
                                <p className="outs">Out</p>
                                <p className="times">6:00 PM</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AttendanceTwo