import React from 'react'

const Annoucement = () => {
    return (
        <div className="card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center">
                    <div>
                        <h6 className="mb-0">Announcement</h6>
                    </div>
                </div>
            </div>
            <div className="announcementsection">
                <div className="announcementbody">
                    <div className="announcementdate">
                        <h6>Announcement</h6>
                        <p>21 Jan 2023</p>
                    </div>
                    <div className="announcementdesc">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, doloremque quo! Quam repellat possimus repellendus consectetur ab non excepturi id autem voluptatem quae quia aliquam, cupiditate alias ullam necessitatibus tempore?</p>
                    </div>
                </div>
                <div className="announcementbody">
                    <div className="announcementdate">
                        <h6>Announcement</h6>
                        <p>21 Jan 2023</p>
                    </div>
                    <div className="announcementdesc">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, doloremque quo! Quam repellat possimus repellendus consectetur ab non excepturi id autem voluptatem quae quia aliquam, cupiditate alias ullam necessitatibus tempore?</p>
                    </div>
                </div>
                <div className="announcementbody">
                    <div className="announcementdate">
                        <h6>Announcement</h6>
                        <p>21 Jan 2023</p>
                    </div>
                    <div className="announcementdesc">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, doloremque quo! Quam repellat possimus repellendus consectetur ab non excepturi id autem voluptatem quae quia aliquam, cupiditate alias ullam necessitatibus tempore?</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Annoucement