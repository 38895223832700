import React, { useEffect, useState } from 'react'
import { PiCakeFill } from 'react-icons/pi'
import { fetchAllDataAPI } from '../utils/api';
import LoadingSpinners from './LoadingSpinners';
import moment from 'moment';

const UpcomingBirthday = () => {
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";
    const [birthday, setBirthday] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getBirthdayInThisMonth()
    }, [])
    const getBirthdayInThisMonth = () => {
        setIsLoading(true);
        fetchAllDataAPI('employee-get-all-birthday-in-this-month').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setBirthday(...[res.data.birthday])
            }
            else {
                setIsLoading(false)
            }
        })
    }
    return (
        <div className="card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center">
                    <div>
                        <h6 className="mb-0">Birthday in this month</h6>
                    </div>
                </div>
            </div>
            <div className="upcomingsection">
                {isLoading ?
                    <LoadingSpinners />
                    :
                    birthday?.map((birthday, index) => (
                        <div className="upcomingbody" key={index}>
                            <div className="img">
                                <img className="rounded-circle" src={BASE_URL + birthday?.emp_foto} alt={birthday?.emp_foto} />
                            </div>
                            <div className="nametitle">
                                <h6>{birthday?.emp_name}</h6>
                                <p>{moment(birthday?.emp_dob).format('ddd, Do MMM')} {moment().format('YYYY')}</p>
                            </div>
                            <div className="icons">
                                <PiCakeFill />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default UpcomingBirthday