import React, { useEffect, useState } from 'react'
import logo from "../assets/logo.webp"
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { AiOutlineDashboard, AiOutlineLogout, AiOutlineMenu, AiOutlineSetting, AiFillCloseCircle } from "react-icons/ai";
import { getSingleDataFromDatabase } from '../utils/api';
import { ImProfile } from "react-icons/im";
import { RiTeamFill } from "react-icons/ri";

const Sidebar = () => {
    const [mobileMenu, setMobileMenu] = useState(false);
    const empid = localStorage.getItem('empdata');
    const [employee, setEmployee] = useState([]);
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";
    const [leader, setLeader] = useState([]);

    const openMobileMenu = () => {
        if (mobileMenu) {
            setMobileMenu(false)
        }
        else {
            setMobileMenu(true)
        }
    }

    useEffect(() => {
        getSingleEmpData(empid);
        getLeaderData(empid);
    }, [empid]);

    const getSingleEmpData = (empid) => {
        getSingleDataFromDatabase(`employee-get-single-data/${empid}`).then((res) => {
            if (res.data.status === 1) {
                setEmployee(...[res.data.employeedata]);
            }
        })
    }
    const navigate = useNavigate();
    function logout() {
        localStorage.clear();
        navigate("/");
    }

    const getLeaderData = (empid) => {
        getSingleDataFromDatabase(`employee-check-team-leader/${empid}`).then((res) => {
            if (res.data.status === 1) {
                setLeader(...[res.data.leader]);
            }
        })
    }
    return (
        <div className="wrapper">
            <div className="topbarSection">
                <div className="topbar">
                    <div className="mobile-toggle-menu">
                        <Link to="/dashboard">
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <nav className="navbar navbar-expand">
                        <div className="user-box dropdown desktopDisplay">
                            <button className="d-flex align-items-center nav-link" role="button">
                                <div className="username">
                                    <div className="userimg">
                                        <img className="rounded-circle" src={BASE_URL + employee?.emp_foto} alt={employee?.emp_foto} />
                                    </div>
                                    <div className="user-info ps-3">
                                        <p className="user-name mb-0 dropdown-toggle" data-bs-toggle="dropdown">{employee?.emp_name}</p>
                                        <p className="designattion mb-0">{employee?.designation_name}</p>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="/my-profile"><AiOutlineSetting />Settings</Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" onClick={logout} to="javascript:void(0)"><AiOutlineLogout />LogOut</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="mobileDisplay">
                            <button onClick={openMobileMenu}>
                                {mobileMenu ?
                                    (
                                        <AiFillCloseCircle className="mobileMenuIcones" />
                                    )
                                    :
                                    (
                                        <AiOutlineMenu className="mobileMenuIcones" />
                                    )
                                }
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="sidebarWrapper desktopDisplay">
                <div className="simplebar-mask">
                    <div className="simplebar-offset">
                        <div className="simplebar-content-wrapper">
                            <div className="simplebar-content mm-active">
                                <ul className="metismenu mm-show" id="menu">
                                    <li>
                                        <NavLink activeClassName="active" to="/dashboard">
                                            <AiOutlineDashboard />
                                            <span>Dashboard</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/leave-report">
                                            <AiOutlineDashboard />
                                            <span>Leave Report</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/my-profile">
                                            <ImProfile />
                                            <span>My Profile</span>
                                        </NavLink>
                                    </li>
                                    {(() => {
                                        if (leader != "") {
                                            return (
                                                <li>
                                                    <NavLink activeClassName="active" to="/team-member">
                                                        <RiTeamFill />
                                                        <span>Team Member</span>
                                                    </NavLink>
                                                </li>
                                            )
                                        }
                                    })()}
                                    <li>
                                        <NavLink onClick={logout} to="javascript:void(0)">
                                            <AiOutlineLogout />
                                            <span>Log Out</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${mobileMenu ? "" : "mobileMenuActive"}`}>
                <div className="mobilesidebarWrapper mobileDisplay">
                    <div className="simplebar-mask">
                        <div className="simplebar-offset">
                            <div className="simplebar-content-wrapper">
                                <div className="simplebar-content mm-active">
                                    <ul className="metismenu mm-show" id="menu">
                                        <li>
                                            <NavLink activeClassName="active" to="/dashboard">
                                                <AiOutlineDashboard />
                                                <span>Dashboard</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/my-profile">
                                                <ImProfile />
                                                <span>My Profile</span>
                                            </NavLink>
                                        </li>
                                        {(() => {
                                            if (leader != "") {
                                                return (

                                                    <NavLink activeClassName="active" to="/team-member">
                                                        <RiTeamFill />
                                                        <span>Team Member</span>
                                                    </NavLink>
                                                )
                                            }
                                        })()}
                                        <li>
                                            <NavLink activeClassName="active" to="/my-profile">
                                                <AiOutlineSetting />
                                                <span>Settings</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink onClick={logout} to="javascript:void(0)">
                                                <AiOutlineLogout />
                                                <span>LogOut</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
