import React, { useEffect, useState } from 'react'
import { PiHandsClappingLight } from "react-icons/pi";
import { fetchAllDataAPI } from '../utils/api'
import { toast } from 'react-toastify'
import LoadingSpinners from './LoadingSpinners'
import moment from 'moment'

const NewJoinees = () => {
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";
    const [newjoining, setNewJoining] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getNewJoining();
    }, []);

    const getNewJoining = () => {
        setIsLoading(true);
        const intervalId = setTimeout(() => {
            fetchAllDataAPI(`employee-new-joining`).then((res) => {
                if (res.data.status === 1) {
                    setIsLoading(false);
                    setNewJoining(...[res.data.newjoining]);
                }
                else {
                    setIsLoading(false);
                    toast.error("Check you connection");
                }
            })
        }, 800);
        return () => {
            clearTimeout(intervalId);
        };
    }

    return (
        <div className="card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center">
                    <div>
                        <h6 className="mb-0">New Joinees</h6>
                    </div>
                </div>
            </div>
            <div className="newjoineesection">
                {isLoading ?
                    <LoadingSpinners />
                    :
                    newjoining?.map((newjoining, index) => (
                        <div className="newjoineebody" key={index}>
                            <div className="img">
                                <img className="rounded-circle" src={BASE_URL + newjoining?.emp_foto} alt={newjoining?.emp_foto} />
                            </div>
                            <div className="nametitle">
                                <h6>{newjoining?.emp_name}</h6>
                                <p>{newjoining?.emp_job_role}</p>
                                <p className="joiningdate">{moment(newjoining?.emp_joining_date).format('ddd, Do MMM YYYY')}</p>
                            </div>
                            <div className="icons">
                                <PiHandsClappingLight />
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default NewJoinees