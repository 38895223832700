import React, { useEffect, useState } from 'react'
import { fetchAllDataAPI } from '../utils/api';
import moment from 'moment';
import { toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';


const Holiday = () => {
    const [holiday, setHoliday] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllHoliday();
    }, []);

    const getAllHoliday = () => {
        setIsLoading(true);
        const intervalId = setTimeout(() => {
            fetchAllDataAPI(`employee-total-holiday`).then((res) => {
                if (res.data.status === 1) {
                    setIsLoading(false);
                    setHoliday(...[res.data.holiday]);
                }
                else {
                    setIsLoading(false);
                    toast.error("Check you connection");
                }
            })
        }, 800);
        return () => {
            clearTimeout(intervalId);
        };
    }
    return (
        <div className="card radius-10 w-100 holidaycard">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center leaveheader">
                    <div>
                        <h6 className="mb-0">Holiday's</h6>
                    </div>
                </div>
            </div>
            <div className="list-group list-group-flush holidaylist">
                <div className="holidaybody table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Holiday Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ?
                                <LoadingSpinner />
                                :
                                holiday?.map((holiday, index) => (
                                    (() => {
                                        if (moment().format("MMM Do YY") === moment(holiday?.holiday_date).format("MMM Do YY")) {
                                            return (
                                                <tr className="highlightholiday">
                                                    <td>{index + 1}</td>
                                                    <td>{moment(holiday?.holiday_date).format('ddd, Do MMM YYYY')}</td>
                                                    <td>{holiday?.holiday_name}</td>
                                                </tr>
                                            )
                                        }
                                        else {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{moment(holiday?.holiday_date).format('ddd, Do MMM YYYY')}</td>
                                                    <td>{holiday?.holiday_name}</td>
                                                </tr>
                                            )
                                        }
                                    })()
                                    
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Holiday