import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import moment from 'moment/moment'
import { GiTeapotLeaves } from "react-icons/gi";
import img1 from "../assets/avatar-6.png"
import img2 from "../assets/avatar-7.png"
import img3 from "../assets/avatar-8.png"
import img4 from "../assets/avatar-9.png"
import img5 from "../assets/avatar-10.png"
import { FaAward, FaBirthdayCake, FaInfo, FaMoneyCheckAlt, FaRupeeSign, FaUserEdit, FaUserTie } from 'react-icons/fa'
import { AiOutlineFilePdf, AiOutlineMail } from 'react-icons/ai'
import { MdContactPage, MdDateRange } from 'react-icons/md'
import { getSingleDataFromDatabase } from '../utils/api';
import { toast } from 'react-toastify'

const MyProfile = () => {
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";
    const empid = localStorage.getItem('empdata');
    const [employee, setEmployee] = useState([]);
    const [empbirth, setEmpBirth] = useState([]);
    const [empfile, setEmpFiles] = useState([]);
    const [empaward, setTotalAward] = useState([]);

    useEffect(() => {
        getSingleEmpData(empid);
        getSingleFileData(empid);
        getTotalAward(empid);
    }, [empid]);

    const getSingleEmpData = (empid) => {
        getSingleDataFromDatabase(`employee-get-detail-data/${empid}`).then((res) => {
            if (res.data.status === 1) {
                setEmployee(...[res.data.employeedata]);
                setEmpBirth(...[res.data.employeebirth]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }
    const getSingleFileData = (empid) => {
        getSingleDataFromDatabase(`employee-get-file-single-data/${empid}`).then((res) => {
            if (res.data.status === 1) {
                setEmpFiles(...[res.data.employeedata]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }
    const getTotalAward = (empid) => {
        getSingleDataFromDatabase(`employee-get-total-award/${empid}`).then((res) => {
            if (res.data.status === 1) {
                setTotalAward(...[res.data.award]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }
    return (
        <div className="empProfile">
            <Helmet>
                <title>SATKARTAR:EMPLOYEE::LEAVE REPORT</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">My Profile</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="main-body">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <img src={BASE_URL + employee?.emp_foto} className="img-fluid empfoto" alt={employee?.emp_foto} />
                                                </div>
                                                <div className="col-md-8 empnamedetail">
                                                    <h4 className="empnames">{employee?.emp_name}</h4>
                                                    <div className="sticky_header__availabl sticky_availabl">
                                                        <span className="sticky_header__available_circle"></span>
                                                        <p>Available for work</p>
                                                    </div>
                                                    <p className="mb-1 empdesignation">{employee?.designation_name}</p>
                                                    <p className="text-muted font-size-sm">{employee?.emp_company_email}</p>
                                                </div>
                                            </div>
                                            <div className="btninfo">
                                                <Link to="/" className="btnsendemail"><AiOutlineMail />Send Email</Link>
                                                <div className="empjoingdetail">
                                                    <div className="dobicons">
                                                        <MdDateRange />
                                                    </div>
                                                    <div className="empjoingdetails">
                                                        <span className="dob">Today</span>
                                                        <span> {moment().format("ddd Do MMM YY")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* Total Award */}
                                <div className="card awardcard">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="award">
                                                <div className="totalaward">
                                                    <h4 className="awardnames">Total awards</h4>
                                                    <span className="totalaward">{empaward}</span>
                                                </div>
                                                <div className="awardicons">
                                                    <FaAward />
                                                </div>
                                            </div>
                                            <div className="awardsinfo">
                                                <p>10% higher then last year</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Total Award */}
                                {/* Project */}
                                <div className="card projectcard">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="project">
                                                <div className="totalproject">
                                                    <h4 className="projectnames">Project assigned</h4>
                                                    <span className="totalproject">10</span>
                                                </div>
                                                <div className="projecticons">
                                                    <MdContactPage />
                                                </div>
                                            </div>
                                            <div className="projectsinfo">
                                                <p>Pending Project: 05</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Project */}
                                {/* Leave */}
                                <div className="card leavecard">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="leave">
                                                <div className="totalleave">
                                                    <h4 className="leavenames">Available Leave</h4>
                                                    <span className="totalleave">{employee?.emp_total_leave_available}</span>
                                                </div>
                                                <div className="leaveicons">
                                                    <GiTeapotLeaves />
                                                </div>
                                            </div>
                                            <div className="leavesinfo">
                                                <p>Leave Applied: 05</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Project */}
                            </div>
                            <div className="col-lg-9 empinfodetails">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title"><FaInfo />Information </h4>
                                        <hr />
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Name</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Emp Code</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_code}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Mobile</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_mobile}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Company Email</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_company_email}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Designation</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.designation_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Role</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_job_role}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Basic Salary</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_basic_salary}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Total CTC</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_ctc}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Department</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_department}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Gender</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_gender}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>DOB</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Joining Date</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span className="badge spanbadge text-white">{moment(employee?.emp_joining_date).format('ddd, Do MMM YYYY')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Seating Location</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>CP, New Delhi</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Employeer</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.company_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Job Type</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_job_type}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Work From</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_work_form}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Reporting Head</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>Sushil Kumar Raj</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Status</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span className="badge btn-success badgecolor">{employee?.emp_status}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="personalinfo">
                                    <div className="card-body">
                                        <div className="cardheading d-flex justify-content-between">
                                            <h4 className="card-title"><FaInfo />Personal Information </h4>
                                            <Link className="btnInfo btn btn-sm btn-primary" to={`/edit-my-profile/${employee?.employee_id}`}><FaUserEdit />Edit Info</Link>
                                        </div>
                                        <hr />
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Personal Email</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_personal_email}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Experience</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_experience}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Married Status</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_married_status}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Aniversary Date</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{moment(employee?.emp_aniversary_date).format('ddd, Do MMM YYYY')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Linkedin</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_linkedin}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Present Address</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_present_address}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Permanent Address</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_permanent_address}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Skill Set</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            {employee?.emp_skill_set}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Facebook</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{employee?.emp_facebook}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 officeladocuments">
                                <div className="card">
                                    <div className="card-body bg-white officeladocument">
                                        <div className="row">
                                            <div className="cardheading">
                                                <div className="officialdocument">
                                                    <h6>Official Document</h6>
                                                    <hr />
                                                    <div className="officialdocumentbody">
                                                        <div className="col-md-2">
                                                            <div className="documentinfo">
                                                                <div className="documenttitle">
                                                                    {(() => {
                                                                        if (empfile?.emp_cirtification != "") {
                                                                            return (
                                                                                <Link target="_blank" to={BASE_URL + empfile?.emp_cirtification}>
                                                                                    <span><AiOutlineFilePdf />Cirtification</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <Link to="javascript:void(0)">
                                                                                    <span><AiOutlineFilePdf />Cirtification</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="documentinfo">
                                                                <div className="documenttitle">
                                                                    {(() => {
                                                                        if (empfile?.emp_adhaar != "") {
                                                                            return (
                                                                                <Link target="_blank" to={BASE_URL + empfile?.emp_adhaar}>
                                                                                    <span><AiOutlineFilePdf />Adhaar Card</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <Link to="javascript:void(0)">
                                                                                    <span><AiOutlineFilePdf />Adhaar Card</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="documentinfo">
                                                                <div className="documenttitle">
                                                                    {(() => {
                                                                        if (empfile?.emp_pancard != "") {
                                                                            return (
                                                                                <Link target="_blank" to={BASE_URL + empfile?.emp_pancard}>
                                                                                    <span><AiOutlineFilePdf />Pan Card</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <Link to="javascript:void(0)">
                                                                                    <span><AiOutlineFilePdf />Pan Card</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="documentinfo">
                                                                <div className="documenttitle">
                                                                    {(() => {
                                                                        if (empfile?.emp_offerlatter != "") {
                                                                            return (
                                                                                <Link target="_blank" to={BASE_URL + empfile?.emp_offerlatter}>
                                                                                    <span><AiOutlineFilePdf />Offer Latter</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <Link to="javascript:void(0)">
                                                                                    <span><AiOutlineFilePdf />Offer Latter</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="documentinfo">
                                                                <div className="documenttitle">
                                                                    {(() => {
                                                                        if (empfile?.emp_resume != "") {
                                                                            return (
                                                                                <Link target="_blank" to={BASE_URL + empfile?.emp_resume}>
                                                                                    <span><AiOutlineFilePdf />Resume</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <Link to="javascript:void(0)">
                                                                                    <span><AiOutlineFilePdf />Resume</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="documentinfo">
                                                                <div className="documenttitle">
                                                                    {(() => {
                                                                        if (empfile?.emp_expletter != "") {
                                                                            return (
                                                                                <Link target="_blank" to={BASE_URL + empfile?.emp_expletter}>
                                                                                    <span><AiOutlineFilePdf />Resume</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <Link to="javascript:void(0)">
                                                                                    <span><AiOutlineFilePdf />Resume</span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="emptypedetails">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="cardemptype">
                                                <div className="emptypetitle">
                                                    <h6>Monthly Attendance</h6>
                                                    <div className="selectMonth">
                                                        <select name="selectmonth">
                                                            <option value="Jan">Jan</option>
                                                            <option value="Feb">Feb</option>
                                                            <option value="March">March</option>
                                                            <option value="April">April</option>
                                                            <option value="May">May</option>
                                                            <option value="June">June</option>
                                                            <option value="July">July</option>
                                                            <option value="Aug">Aug</option>
                                                            <option value="Sep">Sep</option>
                                                            <option value="Oct">Oct</option>
                                                            <option value="Nov">Nov</option>
                                                            <option value="Dec">Dec</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="leaveEmpDetail">
                                                    <div className="leave">
                                                        <p>Leave</p>
                                                        <span>06</span>
                                                    </div>
                                                    <div className="halfday">
                                                        <p>Half Day</p>
                                                        <span>00</span>
                                                    </div>
                                                    <div className="fullday">
                                                        <p>Full Day</p>
                                                        <span>06</span>
                                                    </div>
                                                    <div className="sortleave">
                                                        <p>Sort Leave</p>
                                                        <span>02</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="cardemptype">
                                                <div className="emptypetitle">
                                                    <h6>Team Member</h6>
                                                </div>
                                                <div className="totalimgnumber">
                                                    <div className="teammemberimg">
                                                        <div className="img">
                                                            <img src={BASE_URL + employee?.emp_foto} alt={employee?.emp_foto} />
                                                        </div>
                                                        <div className="imgs">
                                                            <img src={img1} alt={employee?.emp_foto} />
                                                        </div>
                                                        <div className="imgs">
                                                            <img src={img2} alt={employee?.emp_foto} />
                                                        </div>
                                                        <div className="imgs">
                                                            <img src={img3} alt={employee?.emp_foto} />
                                                        </div>
                                                        <div className="imgs">
                                                            <img src={img4} alt={employee?.emp_foto} />
                                                        </div>
                                                        <div className="imgs">
                                                            <img src={img5} alt={employee?.emp_foto} />
                                                        </div>
                                                    </div>
                                                    <div className="teamnumber">
                                                        <span>+02</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="cardemptype cardemptypes">
                                                <div className="emptypetitle">
                                                    <h6>Birthday</h6>
                                                    <FaBirthdayCake className="birthicons" />
                                                </div>
                                                <div className="empbirthday">
                                                    <span>{moment(empbirth).format('dddd, Do MMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="cardemptype cardemptypeb">
                                                <div className="emptypetitle">
                                                    <h6>Last Month Salary</h6>
                                                    <FaMoneyCheckAlt className="birthicons" />
                                                </div>
                                                <div className="lastmonthsalry">
                                                    <span><FaRupeeSign />25000.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default MyProfile