import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { AiOutlinePlus } from 'react-icons/ai'
import { fetchAllDataAPI } from '../utils/api'
import LoadingSpinner from '../components/LoadingSpinner'
import { FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'

const TeamMember = () => {
    const [member, setMember] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const empid = localStorage.getItem('empdata');

    useEffect(() => {
        getAllTeamMember(empid);
    }, [empid])

    const getAllTeamMember = () => {
        setIsLoading(true);
        fetchAllDataAPI(`employee-get-all-team-member/${empid}`).then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setMember(...[res.data.member])
            }
            else {
                setIsLoading(false);
            }
        })
    }
    const handleRemoveMember = (memberid) => {
        setIsLoading(true);
        fetchAllDataAPI(`employee-member-from-member-list/${memberid}`).then((res)=>{
            if(res.data.status===1){
                setIsLoading(false);
                getAllTeamMember(empid);
                toast.error("Team member has been removed!!");
            }
            else{
                setIsLoading(false)
            }
        })
    }
    return (
        <div className="empDashboard">
            <Helmet>
                <title>SATKARTAR:EMPLOYEE::ALL TEAM MEMBER</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper leaverow">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Team Member</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="card tablecard">
                        <div className="headingtitle">
                            <h6 className="mb-0 text-uppercase">Team Member</h6>
                            <Link to="/add-team-member" className="btn btn-sm btn-success"><AiOutlinePlus /> Add Team Member</Link>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table id="example" className="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Status</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <LoadingSpinner />
                                            :
                                            member?.map((member, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{member?.emp_name}</td>
                                                    <td>{member?.emp_company_email}</td>
                                                    <td>{member?.emp_mobile}</td>
                                                    <td>{member?.add_status}</td>
                                                    <td>
                                                        <button className="btnRemove" onClick={() => handleRemoveMember(member?.member_id)}><FaTrash /></button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Status</th>
                                            <th>Remove</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default TeamMember