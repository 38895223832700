import React, { useState } from 'react'
import favicon from "../assets/favicon.png"
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reSendMobileOTP, verifyMobileOTPAPI } from '../utils/api';
import loadingif from "../assets/loading-gif.gif";

const MobileOTP = () => {
    const [mobileotp, setMobileOTP] = useState("");
    const mobile = localStorage.getItem("empmobile");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const NumericOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) setMobileOTP(e.target.value)
        else e.target.value = preval.substring(0, (preval.length - 1))
    }
    const handleOTPSubmit = async (e) => {
        e.preventDefault();
        if (mobileotp.length === 4) {
            setLoading(true);
            const mobiledata = {
                mobile,
                mobileotp,
            };
            verifyMobileOTPAPI("employee-verify-mobile-otp", mobiledata).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("empdata", res.data.data);
                    navigate("/dashboard");
                    toast.success(res.data.msg);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                    toast.error(res.data.msg);
                }
            })
        }
        else {
            setLoading(false);
            toast.error("Invalid Mobile OTP!!");
        }
    }
    const resendMobileOTP = () => {
        if (mobile.length === 10) {
            reSendMobileOTP("employee-resend-mobile-otp", mobile).then((res) => {
                if (res.data.status === 1) {
                    toast.success("Mobile OTP has been send!!")
                }
                else {
                    toast.error("Check you connection!!")
                }
            })
        }
        else {
            toast.error("Invalid Mobile Number!!");
        }
    }
    return (
        <div className="homePage">
            <Helmet>
                <title>SATKARTAR:MOBILE OTP</title>
            </Helmet>
            <div className="container h-100">
                <div className="d-flex justify-content-center h-100">
                    <div className="user_card">
                        <div className="d-flex justify-content-center">
                            <div className="brand_logo_container">
                                <img src={favicon} className="brand_logo" alt="Logo" />
                            </div>
                            <h1 className="loginnames">EMPLOYEE LOGIN</h1>
                        </div>
                        <div className="d-flex justify-content-center form_container">
                            <form onSubmit={handleOTPSubmit} method="post">
                                <div className="form-group">
                                    <label htmlFor="emp_otp">Enter Mobile OTP</label>
                                    <input type="text" name="emp_otp" className="form-control input_user" id="emp_otp" placeholder="Enter the Mobile OTP" onChange={NumericOnly} required={true} maxLength={6} />
                                </div>
                                <div className="d-flex justify-content-end login_container">
                                    <a href="javascript:void(0)" className="reSendMobileOTP" onClick={resendMobileOTP}>Re-Send Mobile OTP</a>
                                </div>
                                <div className="d-flex justify-content-center mt-3 login_container">
                                    {loading ?
                                        <button disabled={true} type="submit" className="btn login_btn">
                                            <img src={loadingif} alt={loadingif} style={{ width: "7%", marginTop: "-3px" }} /> VERIFY OTP...
                                        </button>
                                        :
                                        <button type="submit" className="btn login_btn">LOGIN</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileOTP
