import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getSingleDataFromDatabase, updateDataAPI, userUploadProfilePicAPI } from '../utils/api'
import { toast } from 'react-toastify'
import { AiOutlineFilePdf } from 'react-icons/ai'


const initialState = {
    emp_name: '',
    emp_personal_email: '',
    emp_present_address: '',
    emp_permanent_address: '',
    emp_skill_set: '',
    emp_married_status: '',
    emp_aniversary_date: '',
    emp_linkedin: '',
    emp_facebook: '',
    emp_experience: '',
}

const EditMyProfile = () => {
    const [file, setFile] = useState('');
    const { slug } = useParams();
    const [values, setInputValues] = useState(initialState);
    const [empfile, setEmpFiles] = useState([]);
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";
    const navigate = useNavigate();
    const [cirtification, setCirtificationFile] = useState('');
    const [adhaar, setAdhaarFile] = useState('');
    const [pancard, setPanCardFile] = useState('');
    const [offerlatter, setOfferLatterFile] = useState('');
    const [resume, setResumeFile] = useState('');
    const [expletter, setExpLetterFile] = useState('');

    useEffect(() => {
        getSingleEmpData(slug);
        getSingleFileData(slug);
    }, [slug]);

    const getSingleEmpData = (slug) => {
        getSingleDataFromDatabase(`employee-get-profile-single-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.employeedata]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }
    const getSingleFileData = (slug) => {
        getSingleDataFromDatabase(`employee-get-file-single-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setEmpFiles(...[res.data.employeedata]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }
    const updateEmployeeProfile = async (e) => {
        e.preventDefault();
        try {
            updateDataAPI(`employee-update-profile-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    toast.success("profile has been updated successfully!!");
                    navigate("/my-profile")
                }
                else {
                    toast.error("Unable to fetched user data");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    // Update Image
    useEffect(() => {
        if (file) {
            try {
                const data = new FormData();
                data.append("emp_foto", file);
                userUploadProfilePicAPI(`employee-upload-profile-pic/${slug}`, data).then((res) => {
                    if (res.data.status === 1) {
                        getSingleEmpData(slug);
                        toast.success("profile pic updated successfully");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [file]);
    //cirtification
    useEffect(() => {
        if (cirtification) {
            try {
                const data = new FormData();
                data.append("emp_cirtification", cirtification);
                userUploadProfilePicAPI(`employee-upload-document-cirtification/${slug}`, data).then((res) => {
                    if (res.data.status === 1) {
                        getSingleEmpData(slug);
                        toast.success("cirtification has been uploaded successfully!!");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [cirtification]);

    //adhaar
    useEffect(() => {
        if (adhaar) {
            try {
                const data = new FormData();
                data.append("emp_adhaar", adhaar);
                userUploadProfilePicAPI(`employee-upload-document-adhaar/${slug}`, data).then((res) => {
                    if (res.data.status === 1) {
                        getSingleEmpData(slug);
                        toast.success("Adhaar Card has been uploaded successfully!!");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [adhaar]);

    //pancard
    useEffect(() => {
        if (pancard) {
            try {
                const data = new FormData();
                data.append("emp_pancard", pancard);
                userUploadProfilePicAPI(`employee-upload-document-pancard/${slug}`, data).then((res) => {
                    if (res.data.status === 1) {
                        getSingleEmpData(slug);
                        toast.success("Pan Card has been uploaded successfully!!");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [pancard]);

    //offerlatter
    useEffect(() => {
        if (offerlatter) {
            try {
                const data = new FormData();
                data.append("emp_offerlatter", offerlatter);
                userUploadProfilePicAPI(`employee-upload-document-offerlatter/${slug}`, data).then((res) => {
                    if (res.data.status === 1) {
                        getSingleEmpData(slug);
                        toast.success("Offer latter has been uploaded successfully!!");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [offerlatter]);

    //resume
    useEffect(() => {
        if (resume) {
            try {
                const data = new FormData();
                data.append("emp_resume", resume);
                userUploadProfilePicAPI(`employee-upload-document-resume/${slug}`, data).then((res) => {
                    if (res.data.status === 1) {
                        getSingleEmpData(slug);
                        toast.success("Resume has been uploaded successfully!!");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [resume]);

    //expletter
    useEffect(() => {
        if (expletter) {
            try {
                const data = new FormData();
                data.append("emp_expletter", expletter);
                userUploadProfilePicAPI(`employee-upload-document-experience/${slug}`, data).then((res) => {
                    if (res.data.status === 1) {
                        getSingleEmpData(slug);
                        toast.success("Experience Letter has been uploaded successfully!!");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [expletter]);

    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="empProfile">
            <Helmet>
                <title>SATKARTAR:EMPLOYEE::EDIT MY PROFILE</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Edit My Profile</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="main-body">
                        <div className="col-lg-12">
                            <h3 className="fomes">Edit My Profile</h3>
                            <div className="contact-form-div">
                                <form method="post" encType="multipart/form-data" onSubmit={updateEmployeeProfile}>
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-sm-2">
                                            <img src={BASE_URL + values.emp_foto} className="imgProfilePic" />
                                            <label className="labelProfilePic" htmlFor="emp_foto">Change Profile Picture</label>
                                            <input className="profilePic" type="file" id='emp_foto' name="emp_foto" onChange={(e) => setFile(e.target.files[0])} />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label htmlFor="emp_name">Name</label>
                                                    <input name="emp_name" id="emp_name" placeholder="Enter Name..." className="form-control" type="text" required={true} onChange={handleInputs} value={values.emp_name} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label htmlFor="emp_personal_email">Personal Email</label>
                                                    <input placeholder="Enter Email..." type="email" name="emp_personal_email" id="emp_personal_email" className="form-control" required={true} onChange={handleInputs} value={values.emp_personal_email} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label htmlFor="emp_married_status">Married Status</label>
                                                    <select type="text" name="emp_married_status" id="emp_married_status" className="form-control" required={true} onChange={handleInputs}>
                                                        <option value={values.emp_married_status}>{values.emp_married_status}</option>
                                                        <option value="Single">Single</option>
                                                        <option value="Married">Married</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label htmlFor="emp_linkedin">Linkedin Link</label>
                                                    <input placeholder="Linkedin Link" type="text" name="emp_linkedin" id="emp_linkedin" required={true} className="form-control" onChange={handleInputs} value={values.emp_linkedin} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label htmlFor="emp_facebook">Facebook Link</label>
                                                    <input placeholder="Facebook Link" type="text" name="emp_facebook" id="emp_facebook" required={true} className="form-control" onChange={handleInputs} value={values.emp_facebook} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label htmlFor="
                                                    emp_aniversary_date">Aniversary Date</label>
                                                    <input placeholder="City" type="date" name="emp_aniversary_date" id="emp_aniversary_date" className="form-control" required={true} onChange={handleInputs} value={values.emp_aniversary_date} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Zipcode</label>
                                                    <input placeholder="Pincode" type="text" name="pincode" id="pincode" required={true} className="form-control" onChange={handleInputs} value={values.pincode} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Total Experience</label>
                                                    <input placeholder="4 Years" type="text" name="emp_experience" id="emp_experience" required={true} className="form-control" onChange={handleInputs} value={values.emp_experience} />
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <label htmlFor="emp_skill_set">Skill Set with comma seperated</label>
                                                    <textarea type="text" name="emp_skill_set" id="emp_skill_set" className="form-control" required={true} placeholder="example1, example2" value={values.emp_skill_set} onChange={handleInputs}></textarea>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <label htmlFor="emp_present_address">Present Address</label>
                                                    <textarea type="text" name="emp_present_address" id="emp_present_address" className="form-control" required={true} value={values.emp_present_address} onChange={handleInputs}></textarea>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <label htmlFor="emp_permanent_address">Permanent Address</label>
                                                    <textarea type="text" name="emp_permanent_address" id="emp_permanent_address" className="form-control" required={true} value={values.emp_permanent_address} onChange={handleInputs}></textarea>
                                                </div>
                                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                                    <button className="userBtn mt-0">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="main-body mt-3 empdocument">
                        <div className="col-lg-12">
                            <h3 className="fomes">Upload Documents(<small>Only PDF File</small>) FOR Office USE</h3>
                            <div className="contact-form-div">
                                <div className="col-lg-2 col-md-2 col-sm-2 empdocumentbody">
                                    {(() => {
                                        if (empfile?.emp_cirtification != "") {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link target="_blank" to={BASE_URL + empfile?.emp_cirtification}><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link to="javascript:void(0)"><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                    })()}
                                    <div className="empdocumentlin">
                                        <label className="labeldocument" htmlFor="emp_cirtification">Cirtification</label>
                                        <input className="labeldocumentinput" type="file" id='emp_cirtification' name="emp_cirtification" onChange={(e) => setCirtificationFile(e.target.files[0])} />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 empdocumentbody">
                                    {(() => {
                                        if (empfile?.emp_adhaar != "") {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link target="_blank" to={BASE_URL + empfile?.emp_adhaar}><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link to="javascript:void(0)"><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                    })()}
                                    <div className="empdocumentlin">
                                        <label className="labeldocument" htmlFor="emp_adhaar">Adhaar Card</label>
                                        <input className="labeldocumentinput" type="file" id='emp_adhaar' name="emp_adhaar" onChange={(e) => setAdhaarFile(e.target.files[0])} />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 empdocumentbody">
                                    {(() => {
                                        if (empfile?.emp_pancard != "") {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link target="_blank" to={BASE_URL + empfile?.emp_pancard}><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link to="javascript:void(0)"><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                    })()}
                                    <div className="empdocumentlin">
                                        <label className="labeldocument" htmlFor="emp_pancard">Pan Card</label>
                                        <input className="labeldocumentinput" type="file" id='emp_pancard' name="emp_pancard" onChange={(e) => setPanCardFile(e.target.files[0])} />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 empdocumentbody">
                                    {(() => {
                                        if (empfile?.emp_offerlatter != "") {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link target="_blank" to={BASE_URL + empfile?.emp_offerlatter}><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link to="javascript:void(0)"><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                    })()}
                                    <div className="empdocumentlin">
                                        <label className="labeldocument" htmlFor="emp_offerlatter">Offer Latter</label>
                                        <input className="labeldocumentinput" type="file" id='emp_offerlatter' name="emp_offerlatter" onChange={(e) => setOfferLatterFile(e.target.files[0])} />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 empdocumentbody">
                                    {(() => {
                                        if (empfile?.emp_resume != "") {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link target="_blank" to={BASE_URL + empfile?.emp_resume}><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link to="javascript:void(0)"><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                    })()}
                                    <div className="empdocumentlin">
                                        <label className="labeldocument" htmlFor="emp_resume">Resume</label>
                                        <input className="labeldocumentinput" type="file" id='emp_resume' name="emp_resume" onChange={(e) => setResumeFile(e.target.files[0])} />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 empdocumentbody">
                                    {(() => {
                                        if (empfile?.emp_expletter != "") {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link target="_blank" to={BASE_URL + empfile?.emp_expletter}><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="empdocumentbodypdf">
                                                    <Link to="javascript:void(0)"><AiOutlineFilePdf /></Link>
                                                </div>
                                            )
                                        }
                                    })()}
                                    <div className="empdocumentlin">
                                        <label className="labeldocument" htmlFor="emp_expletter">Experience Latter</label>
                                        <input className="labeldocumentinput" type="file" id='emp_expletter' name="emp_expletter" onChange={(e) => setExpLetterFile(e.target.files[0])} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper >
        </div >
    )
}

export default EditMyProfile