import React, { useEffect, useState } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getSingleDataFromDatabase } from '../utils/api';
import { toast } from 'react-toastify';
import LoadingSpinners from './LoadingSpinners';

const percentage = 15;

const LeaveBalance = () => {
    const empid = localStorage.getItem('empdata');
    const [leave, setLeaveBalance] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getTotalLeaveBalance(empid);
    }, [empid]);

    const getTotalLeaveBalance = (empid) => {
        setIsLoading(true);
        const intervalId = setTimeout(() => {
            getSingleDataFromDatabase(`employee-total-leave-balance/${empid}`).then((res) => {
                if (res.data.status === 1) {
                    setIsLoading(false);
                    setLeaveBalance(...[res.data.leave]);
                }
                else {
                    setIsLoading(false);
                    toast.error("Check you connection");
                }
            })
        }, 800);
        return () => {
            clearTimeout(intervalId);
        };
    }
    return (
        <div className="card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center">
                    <div>
                        <h6 className="mb-0">Leave Balance</h6>
                    </div>
                </div>
            </div>

            <div className="leavebalancebody">
                <div className="row">
                    <div className="col-md-4">
                        <div className="leaveexample">
                            <ul>
                                <li><span className="casual">Casual Leave</span></li>
                                <li><span className="sick">Sick Leave</span></li>
                                <li><span className="medical">Medical Leave</span></li>
                                <li><span className="marriage">Marriage Leave</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-8">
                        {isLoading ?
                            <LoadingSpinners />
                            :
                            <div className="leavebalancesection">
                                <div>
                                    <CircularProgressbar maxValue={leave?.emp_total_leave_available} value={percentage} text={`${percentage}/${leave?.emp_total_leave_available}`}
                                        styles={buildStyles({
                                            pathColor: '#fb2c71',
                                            textColor: 'orange',
                                            trailColor: '#d6d6d6',
                                        })} />
                                    <div className="totalleft">
                                        <h6>Total Left</h6>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaveBalance