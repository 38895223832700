import React, { useState } from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AttendanceTwo from '../components/AttendanceTwo'
import Notification from '../components/Notification'
import LeaveBalance from '../components/LeaveBalance'
import LeaveApprovedReport from '../components/LeaveApprovedReport'
import NewJoinees from '../components/NewJoinees'
import Anniversaries from '../components/Anniversaries'
import UpcomingBirthday from '../components/UpcomingBirthday'
import LeaveApproved from '../components/LeaveApproved'
import Holiday from '../components/Holiday'
import Annoucement from '../components/Annoucement'
import { toast } from 'react-toastify'
import { getSingleDataFromDatabase } from '../utils/api'
import moment from 'moment'

const Dashboard = () => {
    const navigate = useNavigate();
    const empid = localStorage.getItem('empdata');
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";
    const [employee, setEmployee] = useState([]);

    useEffect(() => {
        if (empid) {
            navigate('/dashboard');
            getDashboardSingleEmpData(empid);
        }
        else {
            navigate("/");
        }
    }, [empid]);

    const getDashboardSingleEmpData = (empid) => {
        getSingleDataFromDatabase(`employee-dashboard-single-all/${empid}`).then((res) => {
            if (res.data.status === 1) {
                setEmployee(...[res.data.dashboard]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }

    return (
        <div className="hrDashboard">
            <Helmet>
                <title>SATKARTAR:EMPLOYEE::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                {(() => {
                    if (moment().format('a') === "pm") {
                        return (
                            <h4 className="dashboardname">Good Afternoon, <span>{employee?.emp_name}</span></h4>
                        )
                    }
                    else {
                        return (
                            <h4 className="dashboardname">Good Morning, <span>{employee?.emp_name}</span></h4>
                        )
                    }
                })()}
                {/* Attendance and Notification */}
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-4">
                        <AttendanceTwo />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-8 d-flex">
                        <Notification />
                    </div>
                </div>
                {/* End */}
                {/* Leave Balance */}
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-8">
                        <LeaveApprovedReport />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-4">
                        <LeaveBalance />
                    </div>
                </div>
                {/* End */}
                {/* New Joining Approvel birthday */}
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-3">
                        <NewJoinees />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-3">
                        <Anniversaries />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-3">
                        <UpcomingBirthday />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-3">
                        <LeaveApproved />
                    </div>
                </div>
                {/* End */}
                {/* Holiday and annoucement */}
                <div className="row birthdayrow">
                    <div className="col-12 col-lg-6 col-xl-6">
                        <Holiday />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6">
                        <Annoucement />
                    </div>
                </div>
                {/* End */}
            </ContentWrapper>
        </div>
    )
}

export default Dashboard
