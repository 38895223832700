import React, { useEffect, useState } from 'react'
import { fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import LoadingSpinners from './LoadingSpinners';

const Notification = () => {
    const [notification, setNotification] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllNotification();
    }, []);

    const getAllNotification = () => {
        setIsLoading(true);
        const intervalId = setTimeout(() => {
            fetchAllDataAPI(`employee-get-all-notification`).then((res) => {
                if (res.data.status === 1) {
                    setIsLoading(false);
                    setNotification(...[res.data.notification]);
                }
                else {
                    setIsLoading(false);
                    toast.error("Check you connection");
                }
            })
        }, 800);
        return () => {
            clearTimeout(intervalId);
        };
    }
    return (
        <div className="card radius-10 w-100 notificationbody">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center">
                    <div>
                        <h6 className="mb-0">Notifications</h6>
                    </div>
                </div>
            </div>
            {isLoading ?
                <LoadingSpinners />
                :
                <ul className="list-group list-group-flush notificationlist">
                    {notification?.map((notification, index) => (
                    <li className="list-group-item bg-transparent border-bottom notificationli" key={index}>
                        <div className="d-flex align-items-center notification">
                            <p className="small-font notificationbody">{notification?.notification_name}</p>
                            <p className="notificationdate badge bg-secondary">{moment(notification?.create_date).format('ddd, Do MMM YYYY')}</p>
                        </div>
                    </li>
                    ))}
                </ul>
            }
        </div>
    )
}

export default Notification