import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import LoadingSpinners from './LoadingSpinner';
import { fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import moment from 'moment';

const LeaveApprovedReport = () => {
    const [leave, setLeave] = useState([]);
    const empid = localStorage.getItem('empdata');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getLeaveReuest(empid);
    }, []);

    const getLeaveReuest = (empid) => {
        setIsLoading(true);
        const intervalId = setTimeout(() => {
            fetchAllDataAPI(`employee-all-leave-request/${empid}`).then((res) => {
                if (res.data.status === 1) {
                    setIsLoading(false);
                    setLeave(...[res.data.leavedata]);
                }
                else {
                    setIsLoading(false);
                    toast.error("Check you connection");
                }
            })
        }, 800);
        return () => {
            clearTimeout(intervalId);
        };
    }
    return (
        <div className="card radius-10 w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center leaveheader">
                    <div>
                        <h6 className="mb-0">Leave Approved Reports</h6>
                    </div>
                    <div className="addleave">
                        <Link className="btn btn-primary btn-sm" to="/add-leave"><AiOutlinePlus />Add</Link>
                    </div>
                </div>
            </div>
            <div className="list-group list-group-flush leavereportlist">
                <div className="leavebody table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Leave Type</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ?
                                <LoadingSpinners />
                                :
                                leave?.map((leave, index) => (
                                    <tr key={index}>
                                        <td>{leave?.leave_type}</td>
                                        <td>{moment(leave?.leave_start_date).format('ddd, Do MMM YYYY')}</td>
                                        <td>{moment(leave?.leave_end_date).format('ddd, Do MMM YYYY')}</td>
                                        <td>
                                            {(() => {
                                                if (leave?.leave_status === "Pending") {
                                                    return (
                                                        <span className="badge bg-warning">Pending</span>
                                                    )
                                                }
                                                if (leave?.leave_status === "Approved") {
                                                    return (
                                                        <span className="badge bg-success">Approved</span>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <span className="badge bg-danger">Reject</span>
                                                    )
                                                }
                                            })()}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default LeaveApprovedReport