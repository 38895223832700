import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { AiOutlinePlus } from 'react-icons/ai'
import { fetchAllDataAPI } from '../utils/api'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import LoadingSpinner from '../components/LoadingSpinner'

const LeaveReport = () => {
    const [leave, setLeave] = useState([]);
    const empid = localStorage.getItem('empdata');
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 10;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = leave.slice(firstIndex, lastIndex);
    const npage = Math.ceil(leave.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    useEffect(() => {
        getLeaveReuest(empid);
    }, []);

    const getLeaveReuest = (empid) => {
        setIsLoading(true);
        const intervalId = setTimeout(() => {
            fetchAllDataAPI(`employee-all-leave-request/${empid}`).then((res) => {
                if (res.data.status === 1) {
                    setIsLoading(false);
                    setLeave(...[res.data.leavedata]);
                }
                else {
                    setIsLoading(false);
                    toast.error("Check you connection");
                }
            })
        }, 800);
        return () => {
            clearTimeout(intervalId);
        };
    }

    return (
        <div className="empDashboard">
            <Helmet>
                <title>SATKARTAR:EMPLOYEE::LEAVE REPORT</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper leaverow">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All Orders</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="card tablecard">
                        <div className="headingtitle">
                            <h6 className="mb-0 text-uppercase">Leave Report</h6>
                            <Link to="/add-leave" className="btn btn-sm btn-success"><AiOutlinePlus /> Add</Link>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table id="example" className="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Leave Type</th>
                                            <th>Subject</th>
                                            <th>Text</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Status</th>
                                            <th>Reuest Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <LoadingSpinner />
                                            :
                                            records?.map((leave, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{leave?.leave_type}</td>
                                                    <td>{leave?.leave_subject}</td>
                                                    <td>{leave?.leave_message}</td>
                                                    <td><span className="badge spanbadge">{moment(leave?.leave_start_date).format('ddd, Do MMM YYYY')}</span></td>
                                                    <td><span className="badge spanbadge">{moment(leave?.leave_end_date).format('ddd, Do MMM YYYY')}</span></td>
                                                    <td>
                                                        {(() => {
                                                            if (leave?.leave_status === "Pending") {
                                                                return (
                                                                    <span className="badge bg-warning">{leave?.leave_status}</span>
                                                                )
                                                            }
                                                            else if (leave?.leave_status === "Approved") {
                                                                return (
                                                                    <span className="badge bg-success">{leave?.leave_status}</span>
                                                                )
                                                            }
                                                            else if(leave?.leave_status === "Reject"){
                                                                return (
                                                                    <span className="badge bg-danger">{leave?.leave_status}</span>
                                                                )
                                                            }
                                                        })()}
                                                    </td>
                                                    <td><span className="badge spanbadge">{moment(leave?.request_date).format('ddd, Do MMM YYYY')}</span></td>
                                                </tr>
                                            ))}

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Leave Type</th>
                                            <th>Subject</th>
                                            <th>Text</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Status</th>
                                            <th>Reuest Date</th>
                                        </tr>
                                    </tfoot>
                                </table>
                                <nav>
                                    <ul className="pagination justify-content-end round-pagination">
                                        <li className="page-item">
                                            <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                        </li>
                                        {
                                            numbers.map((n, i) => (
                                                <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                    <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                                </li>
                                            ))
                                        }
                                        <li className="page-item">
                                            <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default LeaveReport